export default {
	flowevent: 'Flowevent',
  forget_password: 'Olvidé mi contraseña',
  send_code_title: 'Enviar código',
  restore_code: 'Código de validación',
  confirm: 'Confirmar',
  become_premium: 'Quiero ser premium',
	home: 'Inicio',
	new_version_available: 'Nueva versión disponible.',
	code: 'Código',
	scan_code: 'Leer QR',
	read_code: 'Leer código',
	scaner: 'Lector',
	signout: 'Cerrar sesión',
	register: 'Registro',
  close: 'Cerrar',
  start: 'Comenzar',
	finish: 'Finalizar',
	number: 'Nº',
	plan: 'Plan',
	organisation: 'Organización',
	organisation_image: 'Imagen de tu empresa (logotipo)',
	email: 'Correo electrónico',
	next: 'Siguiente',
	config: 'Configuración',
  no_data_available: 'Sin datos para mostrar',
  working_on_statistics: 'Estamos trabajando en este módulo.<br>Pronto podrás verlo!',
	scan_to_enter: 'Escanea el QR para ingresar',
	print_list: 'Imprimir lista',
  assisted: 'Asistieron',
  didnt_assist: 'No asistieron',
  entrance_time: 'Horario de ingreso',
	payment: {
    cards: {
      credit_card: 'Tarjeta de crédito',
      debit_card: 'Tarjeta de débito',
      finished_in: 'Finalizada en {digits}',
      new_card: 'Nueva tarjeta'
    },
    rejected: {
      cc_rejected_call_for_authorize: 'Se requiere autorización del banco',
      cc_rejected_insufficient_amount: 'Fondos insuficientes',
      cc_rejected_bad_filled_security_code: 'Código de seguridad erróneo',
      cc_rejected_bad_filled_date: 'Fecha de caducidad errónea',
      cc_rejected_other_reason: 'Pago rechazado, póngase en contacto con su banco',
      cc_rejected_bad_filled_other: 'Error en el pago, por favor revise los datos cargados'
    },
    invalid_parameter_cardNumber: 'Número de tarjeta erróneo',
    invalid_parameter_cardholderName: 'Titular de la tarjeta inválido',
    in_process: 'Su pago está siendo procesado',
		payment_info :'Información de pago',
		plan: 'Plan de pago',
		next_payment: 'El siguiente pago está programado para el',
		payments_history: 'Historial de facturación',
		date:'Fecha',
		state:'Estado',
		amount: 'Monto',
		paid: 'Pagada',
		pending: 'Pendiente',
		subscriptions: {
      renew: 'Renovar',
      renewal_period: 'Se renueva cada mes | Se renueva cada {count} meses',
			annual: 'Anual',
			quarterly: 'Trimestral',
			monthly: 'Mensual',
			unsubscribe: 'Desuscribirme',
			reactivate: 'Reactivar',
			current_plan: 'Tu plan actual',
			up_to: 'Hasta {number}',
			test_emails: 'e-mails de prueba',
			subscribe: 'Suscribirme',
			premium_plan: 'Plan Premium',
			subscription_deadline: 'Su suscripción se termina el',
			subscription_cancel: 'Suscripción cancelada',
			subscriptions_canceled: 'Su suscripción ha sido cancelada.',
			subscription_renew: 'Se renueva automáticamente el',
			unsubscribe_notice: 'Al volver a la cuenta Demo se eliminarán todos los eventos que tengas pendientes. Se reactivarán una vez decidas volver a suscribirte. Podrás continuar viendo tus eventos finalizados.',

		},
    cards: {
      credit_card: 'Tarjeta de crédito',
      debit_card: 'Tarjeta de débito',
      finished_in: 'Finalizada en {digits}',
      new_card: 'Nueva tarjeta'
    },
    rejected: {
      cc_rejected_call_for_authorize: 'Se requiere autorización del banco',
      cc_rejected_insufficient_amount: 'Fondos insuficientes',
      cc_rejected_bad_filled_security_code: 'Código de seguridad erróneo',
      cc_rejected_bad_filled_date: 'Fecha de caducidad errónea',
      cc_rejected_other_reason: 'Pago rechazado, póngase en contacto con su banco',
      cc_rejected_bad_filled_other: 'Error en el pago, por favor revise los datos cargados'
    },
    invalid_parameter_cardNumber: 'Número de tarjeta erróneo',
    invalid_parameter_cardholderName: 'Titular de la tarjeta inválido',
    in_process: 'Su pago está siendo procesado'
  },
	signup: {
    data: 'Tus datos',
    create_account: 'Crear cuenta',
    summary: 'Resumen',
    existing_account: '¿Ya tienes una cuenta?'
	},
	action_to_create:{
		click: 'Click',
		here: 'aquí',
		to_create: 'para crear una.',
		to_create_o:'para crear uno.'
	},
	field: {
    email: 'Correo electrónico',
		username: 'Nombre de usuario',
		name: 'Nombre',
		password: 'Contraseña',
		confirm_password: 'Confirmar contraseña',
    fullname: 'Nombre completo',
    id_type: 'Tipo',
    id_number: 'Nº de documento',
    address: 'Dirección',
		phone: 'Teléfono',
		dni: 'DNI',
		subject: 'Asunto',
		host: 'Anfitrión',
		is_minor: '¿Es menor?',
		ascendent: 'Ascendente',
		descendent: 'Descendiente'
  },
  action: {
		print_codes: 'Imprimir códigos',
		enter: 'Ingresar',
		register: 'Registrarme',
		update: 'Actualizar',
		manual_code_input: 'Ingresar código manualmente',
		click: 'Click',
		filters: 'Filtros',
		filter: 'Filtrar',
		create: 'Crear',
		order_by: 'Ordenar por',
		signin: 'Iniciar sesión',
		not_signup: '¿No tienes cuenta?',
    signup: 'Crear usuario',
		signout: 'Cerrar sesión',
		finish: 'Finalizar',
		delete: 'Eliminar',
		edit: 'Editar',
		next: 'Siguiente',
		download: 'Descargar',
		save: 'Guardar',
		import: 'Importar',
		cancel: 'Cancelar',
		share: 'Compartir',
    install: 'Instalar',
    close: 'Cerrar',
		confirm: 'Confirmar',
		manual_share: 'Compartir manualmente',
		omit: 'Omitir',
		acept: 'Aceptar',
		upload_file: 'Subir archivo',
		add: 'Agregar',
		search: 'Buscar',
		send: 'Enviar',
		add_guests: 'Agregar tarjetas',
		add_hosts: 'Agregar anfitrión',
		edit_guest: 'Editar tarjeta',
		edit_host: 'Editar anfitrión',
		delete_guest: 'Eliminar invitación',
		delete_host: 'Eliminar anfitrión',
		create_user: 'Crear usuario',
		see_sheet: 'Ver planilla',
		upload_files: 'Subir archivo',
    screen_closing: 'Esta pantalla se cerrará automáticamente',
		close_now: 'Cerrar ahora',
		create_event: 'Crear evento',
		create_hosts: 'Crear anfitrión',
		download_import_example: 'Descargar ejemplo',
		enter_guest: 'Ingresar tarjeta',
		delete_event: '¿Realmente desea eliminar el evento?',
		edit_event: 'Editar evento',
		end_event: 'Finalizar evento',
		import_instructions:'Baje el archivo de ejemplo y llene los campos deseados para importar las invitaciones. Luego, suba el archivo modificado y presione el botón "Importar".',
		irreversible_action_warning: 'Esta acción no se puede deshacer.'
	},
  event: {
    hosts: 'Anfitriones',
    guests: 'Invitados',
    address: 'Dirección'
  },
	dates:{
		add_or_delete_days: 'Agregue o quite días de ser necesario.',
		double_click_on_date:'Haga doble click en una fecha para cambiar el horario de la misma.',
		from: 'Desde',
		to: 'Hasta',
		interval: 'Cada',
		slotsPerShift: 'Cupos por turno'
	},
	id_types: {
		dni: "DNI", 
		RUC: "RUC", 
		passport: "Pasaporte", 
		other: "Otro"
	},
	success: {
		congratulations: '¡Felicitaciones!',
		you_already_are: 'Ya eres',
		premium: 'Premium',
		enjoy_all_this_benefits: 'Disfruta de todos estos beneficios',
		unlimited: 'ilimitados',
		up_to_emails: 'Hasta 10000 e-mails por mes',
		event_creation: 'Evento creado con éxito.',
    user_creation: 'Usuario creado con éxito',
		user_update: 'Usuario actulizado con éxito',
		go_home: 'Ir al inicio',
  },
  error: {
		wrong_email_or_pass: 'Correo electrónico o contraseña incorrectos.',
		go_back_home: 'Volver al inicio',
    taken: 'Ya se encuentra en uso',
    must_be_unique: 'Ya se encuentra en uso',
		not_found: '{object} no encontrado',
    url_not_found: 'Lo sentimos, parece que la url a la que quiere acceder no existe.',
		did_not_match: 'No coincide con el usuario',
  },
  object: {
    user: 'Usuario',
  },
  validation: {
		required: '*Obligatorio',
    is_required: 'Este campo es obligatorio',
    payment_method_not_found: 'Número de tarjeta inválido',
    invalid_month: 'Mes inválido',
		invalid_year: 'Año inválido',
		event_name_must_be_lt_n: 'El nombre del evento debe ser menor a 50 caracteres.',
		must_be_a_valid_email: 'Debe ser un email válido',
		date_must_be_gt_today: 'La fecha debe ser mayor al día de hoy.',
    must_be_gte_to_6_chars: 'Debe ser mayor o igual a 6 carácteres',
    must_be_gte_to_8_chars: 'Debe ser mayor o igual a 8 carácteres',
    must_be_gte_to_8_chars: 'Debe ser mayor o igual a 8 carácteres',
    must_be_eq_n: 'Debe ser igual a {number} caracteres',
    must_be_lt_n: 'Debe ser como máximo {number} caracteres',
    must_be_bt_8_7: 'Debe tener entre 7 y 8 dígitos',
		must_be_gt_than_n: 'Debe ser mayor o igual a {number} carácteres',
		exceeds_invitations_limit: 'Supera el límite de invitaciones del evento.',
	},
	events: {
		address: 'Dirección',
		assistance: 'Asistencia',
		assisted: 'Asistieron',
		no_host: 'Sin anfitrión',
		date: 'Fecha',
		didnt_assisted: 'no asistieron',
		dni: 'DNI',
		events: 'Eventos',
		event_name: 'Nombre del evento',
		event_type: 'Tipo de evento',
		entrance_time: 'Horario de ingreso',
		first_tab_home: 'PENDIENTES',
		finished_event: 'Evento finalizado',
		guest_invitations: 'Invitados',
		guest: 'Invitado',
		guest: 'Tarjeta',
		guests: 'Tarjetas',
		guests_max: 'Cantidad máxima de tarjetas.',
		guests_quantity: 'Número de tarjetas',
		home_title: 'Eventos',
		host: 'Anfitrión',
		hosts: 'Anfitriones',
		minor: 'Menor',
		name: 'Nombre',
		no_pending_event: 'No hay ningún evento pendiente.',
		no_guest_to_show: 'No hay tarjetas para mostrar.',
		no_hosts_to_show: 'No hay tarjetas para mostrar.',
		no_finished_event: 'No tiene ningún evento finalizado aún.',
		observation: 'Observaciones',
		pending_entries: 'Ingresos pendientes',
		state: 'Estado',
		second_tab_home: 'FINALIZADOS',
		table: 'Mesa',
		table_number: 'Nº de mesa',
		third_tab_home: 'Días y horarios',
		venue: 'Lugar',
	}
}
