<template>
  <v-container class="py-0">
    <v-app-bar 
      app 
      dense
      clipped-left
      color="primary" 
      dark
      flat>
      <v-toolbar-title>
        <router-link
          :to="{name:'Home'}"
          class="d-flex align-center"
          >
          <img src="@/assets/logo-white.svg" height="20px" />
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <Dropdown />

    </v-app-bar>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

const Dropdown = () => import("./Dropdown");
const Subscriptions = () => import("./Subscriptions");

export default {
  name: "Appbar",

  components: {
    Dropdown,
    Subscriptions
  },

  computed: {
    ...mapGetters([
      'currentUser'
    ])
  },

  data(){
    return{
      dialog: false,
    }
  }
}
</script>
