import Vue from "vue";
import VueRouter from "vue-router";
import NotFound from "../views/NotFound.vue"
import Login from "../views/Login.vue"
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      goHome: true
    }
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import(/* webpackChunkName: "Signup" */ '../views/Signup.vue'),
    meta: {
      goHome: true
    }
  },
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/checkout/succeed",
    name: "Succeed",
    component: () => import(/* webpackChunkName: "CheckoutSucceed" */ '../views/checkout/Succeed.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import(/* webpackChunkName: "Settings" */ '../views/Settings.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/event/:id",
    name: "Event",
    component: () => import(/* webpackChunkName: "ShowEvent" */ '../views/events/ShowEvent.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/invitations/:public_url",
    name: "Barcode",
    component: () => import(/* webpackChunkName: "Barcode" */ '../views/events/Barcode.vue'),
  },
  {
    path: "/event/:id/sheet",
    name: "Event Sheet",
    component: () => import(/* webpackChunkName: "EventSheet" */ '../views/events/EventSheet.vue'),
    meta: {
      requiresAuth: true
    }
  },
  { 
    path: '/404', 
    name: "404",
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue'),
  },  
  { path: '*', redirect: '/404' },
];

const router = new VueRouter({
  routes,
  mode: "history"
});

export default router;

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next({
        name: 'Login'
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.goHome)) {
    if (store.getters.isLoggedIn) {
      next({
        name: 'Home'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})
