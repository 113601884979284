import Vue from "vue";
import ActionCableVue from 'actioncable-vue';
import VueTouch from 'vue-touch';
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "@/style/application.scss"
import axios from 'axios'
import moment from 'moment'
import VueI18n from 'vue-i18n'
import messages from './i18n'
import { apolloProvider } from './graphql/apollo'

Vue.use(VueI18n)

const i18n = new VueI18n({
	locale: 'es', 
	fallbackLocale: 'es',
	silentFallbackWarn: true,
  messages
})

Vue.config.productionTip = false;

Vue.prototype.$http = axios;
Vue.prototype.$moment = moment;

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectionUrl: process.env.VUE_APP_WS_URL, // or function which returns a string with your JWT appended to your server URL as a query parameter
  connectImmediately: true,
});

Vue.use(VueTouch, {name: 'v-touch'});


new Vue({
  router,
	store,
	i18n,
	apolloProvider,
  vuetify,
  render: h => h(App)
}).$mount("#app");
