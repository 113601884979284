<template>
  <v-main id="inspire">
    <v-row class="header-xs hidden-sm-and-up" align="center">
      <v-col
        class="flex-grow-0 flex-shrink-1"
        cols="5"
        style="min-width: 100px"
      >
        <img src="@/assets/logo-flowevent-2.png" height="20px" />
      </v-col>
    </v-row>
    <v-container class="fill-height justify-center" fluid>
      <v-card :flat="$vuetify.breakpoint.xs" width="600px">
        <v-card-title class="pb-0">
          {{ $t("action.signin") }}
        </v-card-title>
        <v-form v-model="valid" lazy-validation ref="form">
          <v-card-text>
            <v-alert
              outlined
              :value="alert"
              type="error"
              transition="scale-transition"
            >
              {{ $t("error.wrong_email_or_pass") }}
            </v-alert>
            <v-text-field
              :label="$t('email')"
              ref="email"
              prepend-icon="mdi-account"
              v-model="login.email"
              type="text"
              :rules="[(v) => !!v || 'El campo es obligatorio']"
            >
            </v-text-field>

            <v-text-field
              id="password"
              :label="$t('field.password')"
              ref="password"
              v-model="login.password"
              prepend-icon="mdi-lock"
              :type="show_password ? 'text' : 'password'"
              :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show_password = !show_password"
              :rules="[(v) => !!v || 'El campo es obligatorio']"
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions class="pr-3">
            <v-spacer></v-spacer>
            <v-btn @click.stop="auth" :disabled="loading" tile color="primary">
              {{ $t("action.enter") }}
            </v-btn>
          </v-card-actions>
        </v-form>
        <v-progress-linear
          color="deep-purple accent-4"
          indeterminate
          v-if="loading"
          rounded
          height="6"
        ></v-progress-linear>
      </v-card>
      <v-row>
        <v-col>
          <p class="text-center">
            {{ $t("action.not_signup") }}
            <router-link to="/signup">{{ $t("action.register") }}</router-link>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";
import { SignIn } from "@/graphql/mutations/users";

export default {
  name: "Login",

  data() {
    return {
      loading: false,
      alert: false,
      valid: true,
      errorMessages: "",
      show_password: false,
      login: {
        email: "",
        password: "",
      },
    };
  },

  computed: {
    ...mapGetters(["url"]),
  },

  methods: {
    auth() {
      if (this.valid) this.valid = this.$refs.form.validate();

      if (this.valid) {
        this.loading = true;

        this.$apollo
          .mutate({
            mutation: SignIn,
            variables: {
              input: {
                credentials: this.login,
              },
            },
          })
          .then((response) => {
            this.$store.commit("login");
            localStorage.setItem("auth_token", response.data.signIn.token);
            this.$store.commit("setCurrentUser", response.data.signIn.user);

            this.$router.push({
              name: "Home",
            });
          })
          .catch((error) => {
            this.alert = true;
            this.loading = false;
          });
      }
    },
  },
};
</script>
