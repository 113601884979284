import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#67318F",
        secondary: "#CBB78B",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        highlight: "#67318F",
      },
      dark: {
        primary: "#67318F",
        secondary: "#CBB78B",
        highlight: "#67318F",
      }
    }
  }
});