<template>
  <v-app>
    <Appbar
      v-if="!$vuetify.breakpoint.xs && !$route.meta.hideAppbar"
      @alert="activateAlert"
    />

    <v-alert
      class="text-center"
      :type="alert.type"
      :value="alert.active"
      transition="slide-y-transition"
      style="margin-top: 12px"
    >
      {{ alert.message }}
    </v-alert>

    <v-container
      fluid
      style="max-width: 1600px; margin: 0 auto"
      :class="$route.meta.hideAppbar ? 'fill-height' : ''"
    >
      <router-view></router-view>
    </v-container>

    <v-snackbar
      bottom
      :value="updateExists"
      :timeout="-1"
      light
      color="grey lighten-3"
    >
      {{ $t("new_version_available") }}
      <v-btn small @click="refreshApp" color="primary" class="ml-6">
        {{ $t("action.update") }}
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import Appbar from "./components/Appbar.vue";
import api from "./api/api";
import update from "./mixins/update";

export default {
  name: "App",

  data: () => ({
    alert: {
      active: false,
    },
    loaded: false,
  }),

  components: {
    Appbar,
  },

  mixins: [update],

  methods: {
    activateAlert(type, message) {
      this.alert.active = true;
      this.alert.type = type;
      this.alert.message = message;
      window.setInterval(() => {
        this.alert.active = false;
      }, 5000);
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },

  mounted() {
    const theme = localStorage.getItem("useDarkTheme");
    if (theme) {
      this.$vuetify.theme.dark = theme == "true";
    }
    if (!this.$store.state.currentUser && this.$store.state.isLoggedIn) {
      api.login(this, localStorage.getItem("auth_token"));
    }
  },
};
</script>
