import gql from "graphql-tag";

export const CreateUser = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      clientMutationId
      user {
        id
        name
        email
      }
    }
  }
`;

export const EditUser = gql`
  mutation editUser($input: EditUserInput!) {
    editUser(input: $input) {
      clientMutationId
      user {
        id
      }
    }
  }
`;

export const SignIn = gql`
  mutation signIn($input: SignInUserInput!) {
    signIn(input: $input) {
      clientMutationId
      token
      user {
        email
        id
        freeTrial
        selectedAgency {
          id
        }
        agencySubscription {
          id
          unsuscribed
          yearly
          dueDate
          period
          payments {
            id
            createdAt
            state
            price
          }
        }
        abilities
        limits
        roleNames
        isAssistant
      }
    }
  }
`;

export const SignoutUser = gql`
  mutation signOut($input: SignOutInput!) {
    signOut(input: $input) {
      success
    }
  }
`;
