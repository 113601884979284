import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: !!localStorage.getItem("auth_token"),
    agencyId: null,
    currentUser: null,
    url: process.env.VUE_APP_RAILS_URL,
    events: [],
    event: {
      hosts: [],
      invitations: []
    },
  },
  mutations: {
    login (state) {
      state.isLoggedIn = true
    },
    logout (state) {
      state.isLoggedIn = false;
    },
    setCurrentUser (state, user) {
      state.currentUser = user;
      state.agencyId = user.selectedAgency.id;
    },
    setEvents(state, events) {
      state.events = events
    },
    resetEvent(state) {
      state.event = {
        hosts: [],
        invitations: []
      }
    },
    setHosts(state, hosts) {
      state.event.hosts = hosts
    },
  },
  getters: {
    isLoggedIn: state => {
      return state.isLoggedIn
    },
    currentUser: state => {
      return state.currentUser
    },
    agencyId: state => {
      return state.agencyId
    },
    events: state => {
      return state.events
    },
    hosts: state => {
      return state.event.hosts
    },
    url: state => {
      return state.url
    },
  },
  actions: {},
  modules: {}
});
