<template>
  <v-main>
    <v-card class="mx-auto" max-width="600">
      <v-card-title>
        <h1 color="primary">404</h1>
      </v-card-title>
      <v-card-text>
        {{$t('error.url_not_found')}}
        <br />
        <router-link to="/">{{$t('error.go_back_home')}}</router-link>
      </v-card-text>
    </v-card>
  </v-main>
</template>

<script>
export default {
  name: 'not-found'
}
</script>