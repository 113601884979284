import { SignIn } from "@/graphql/mutations/users";

var api = {
  login(self, token) {
    self.$apollo
      .mutate({
        mutation: SignIn,
        variables: {
          input: {
            token: token,
          },
        },
      })
      .then((res) => {
        self.$store.commit("setCurrentUser", res.data.signIn.user);
        self.loaded = true;
        self.$i18n.locale = "es";
        if (res.data.signIn.user.isAssistant) {
          self.$router.push({
            name: "Event Sheet",
            params: {
              id: res.data.signIn.user.isAssistant,
            },
          });
        }
      })
      .catch((error) => {
        self.$store.commit("logout");
        localStorage.removeItem("auth_token");
        self.$router.go("Home");
      });
    /*
      .get(self.$store.state.url + "current_user", {
        'headers': { 'Authorization': localStorage.getItem("auth_token") }
      })
      .then(response => {
        self.$store.commit("setCurrentUser", response.data);
      })
      .catch(error => {
        self.$store.commit("logout");
        localStorage.removeItem("auth_token");
        self.$router.go("Home");
      })
      */
  },
};

export default api;
